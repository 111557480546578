import {CallType} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";

/**
 * Agora meeting types
 */
export enum AgoraWindowAction {
	CREATE = "create",
	JOIN = "join",
}

/**
 * Agora meeting redux state
 */
export interface AgoraState {
	isAgoraWindowOpen: boolean;
	windowAction: AgoraWindowAction;
	createdMeetingLink: string;
	roomId: string;
	joinedTime: number;
	leaveTime: number;
	isInvitationDialogOpen: boolean;
	invitationLink: string;
	invitationCreator: number;
	isJoinWithVideo: boolean;
	channelLoadingState: LoadingState;
	channelId: string;
	rtcToken: string;
	callType: CallType;
}
